import React from "react";
import TopMenu from "src/menus/mainMenu/top/Component";
import BreadcrumbComponent from "src/layout/breadcrumb/Component";
import MainMenu from "src/menus/mainMenu/Component";

class HeaderComponent extends React.Component {
  state = {
    siderCollapsed: true,
    scrolled: false,
  };

  handleClick = (e) => {
    console.log("click ", e);
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    const { productName, pageName, extraSmall, small, toggleCollapsed } =
      this.props;

    return (
      <>
        <TopMenu />
        <MainMenu
          {...{
            mode: "horizontal",
            selectedKeys: [current],
            onClick: (e) => this.handleClick(e),
          }}
        />
        {<BreadcrumbComponent {...{ productName, pageName }} />}{" "}
      </>
    );
  }
}

export default HeaderComponent;
