import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "gatsby";

import {
  BulbOutlined,
  AppstoreOutlined,
  BarsOutlined,
  LikeOutlined,
  MailOutlined,
  HomeOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const { SubMenu } = Menu;

export const MainMenu = ({
  mode,
  selectedKeys,
  onClick,
  theme,
  style = {},
}) => (
  <Menu
    {...{
      onClick,
      selectedKeys,
      mode,
      theme,
      style,
    }}
  >
    <Menu.Item
      {...{
        key: "home",
        icon: <HomeOutlined />,
        className: "homeMenuItem",
      }}
    >
      <Link to="/">Inicio</Link>
    </Menu.Item>
    <SubMenu
      {...{
        key: "membrana",
        icon: <BulbOutlined />,
        className: "membranaMenuItem",
        title: "La Membrana de Climate Coating",
      }}
    >
      <Menu.Item key="membrana:how">
        <Link to="/membrana-termoceramica/como-funciona/">¿Cómo funciona?</Link>
      </Menu.Item>
      <Menu.Item key="membrana:why">
        <Link to="/membrana-termoceramica/por-que-elegirla/">
          ¿Por qué elegirla?
        </Link>
      </Menu.Item>
    </SubMenu>
    <SubMenu
      {...{
        key: "usos",
        icon: <BarsOutlined />,
        title: "Aplicaciones",
        className: "aplicacionesMenuItem",
      }}
    >
      <Menu.Item key="usos:exterior">
        <Link to="/productos/thermoprotect/">Fachadas y Exterior</Link>
      </Menu.Item>
      <Menu.Item key="usos:interiores">
        <Link to="/productos/thermoplus/">Interiores</Link>
      </Menu.Item>
      <Menu.Item key="usos:cubiertas">
        <Link to="/productos/thermoactive/">Cubiertas/Tejados</Link>
      </Menu.Item>
      <Menu.Item key="usos:industrial">
        <Link to="/productos/industryspecial/">Aplicaciones Industriales</Link>
      </Menu.Item>
      <Menu.Item key="usos:humedades">
        <Link to="/productos/thermovital/">Humedades</Link>
      </Menu.Item>
    </SubMenu>
    <Menu.Item
      {...{
        key: "referencia",
        icon: <LikeOutlined />,
        className: "referenciaMenuItem",
      }}
    >
      <Link to="/referencias/exterior/">Clientes Satisfechos</Link>
    </Menu.Item>
    <Menu.Item
      {...{
        key: "contacto",
        icon: <MailOutlined />,
        className: "contactMenuItem",
      }}
    >
      <Link to="/contacto/">Contáctenos</Link>
    </Menu.Item>
  </Menu>
);

export default MainMenu;
