import React from "react";
import ccLogoSmall from "src/images/logo-cc-small.jpg";
import { Divider, Menu } from "antd";

import "src/menus/mainMenu/top/style.scss";
import { Link } from "gatsby";

class TopMenu extends React.Component {
  render() {
    return (
      <>
        <div id="greyTopBar">
          {" "}
          <span className="contactDetails">
            E-Mail: revestecnia@revestecnia.com <Divider type="vertical" />{" "}
            Telf: 91 005 3676{" "}
          </span>
        </div>
        <div className="ccTopLogo">
          <Link to={"/"}>
            <img src={ccLogoSmall} alt="logo de Climate Coating Spain" />
          </Link>
        </div>
      </>
    );
  }
}

export default TopMenu;
