import React from "react";
import { Layout } from "antd";
import { Breadcrumb } from "antd";
import { Link } from "gatsby";

import "src/layout/breadcrumb/style.scss";

const { Header, Content } = Layout;

export const BreadcrumbComponent = ({
  productName = false,
  pageName = false,
  ...rest
}) => {
  if (productName)
    return (
      <div className="breadcrumbCC">
        <Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Inicio</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/productos/">Productos</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{productName}</Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      </div>
    );
  if (pageName)
    return (
      <div className="breadcrumbCC">
        <Content>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Inicio</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{pageName}</Breadcrumb.Item>
          </Breadcrumb>
        </Content>
      </div>
    );
  return (
    <div className="breadcrumbCC">
      <Content>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <span style={{ minHeight: "18px", display: "block" }}></span>{" "}
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>
    </div>
  );
};
export default BreadcrumbComponent;
