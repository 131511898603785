import React from "react";
import "@fontsource/raleway";
import "@fontsource/raleway/300.css";
import "@fontsource/raleway/500.css";

import { Layout, Menu } from "antd";

import Sider from "src/menus/mainMenu/sider/Component";

import Footer from "src/layout/footer/Component";
import Header from "src/layout/header/Component";
import SEO from "src/components/system/seo";

import CookieConsent from "react-cookie-consent";

class LayoutGeneral extends React.Component {
  state = {
    siderCollapsed: true,
    scrolled: false,
  };

  toggleCollapsed() {
    console.log("toglle¡¡¡");
    this.setState({
      siderCollapsed: !this.state.siderCollapsed,
    });
  }

  render() {
    const { siderCollapsed } = this.state;
    const {
      productName = false,
      pageName = "ClimateCoating España",
      pageTitle = "ClimateCoating España",
      pageTitleJSX,
      pageDescription = "Membrana TermoCerámica con rendimiento Endotérmico",
      showPageTitle = true,
    } = this.props;

    return (
      <>
        <SEO {...{ title: pageTitle, description: pageDescription }} />
        <Layout>
          <Sider
            {...{
              siderCollapsed,
              toggleCollapsed: () => this.toggleCollapsed(),
            }}
          />

          <Layout>
            <div
              {...{
                className: `topMenu`,
              }}
            >
              <Header
                {...{
                  productName,
                  pageName,
                }}
              />
            </div>
            {pageTitle && showPageTitle ? (
              <div className="titleBlock">
                <h1 className="pageTitle centered">
                  {pageTitleJSX ? pageTitleJSX : pageTitle}
                </h1>
                <div className="titleBlockOutside1"></div>
                <div className="titleBlockOutside2"></div>
                <div className="titleBlockOutside3"></div>
              </div>
            ) : (
              ""
            )}
            {this.props.children}
            <Footer />
            <CookieConsent
              location="bottom"
              buttonText="Aceptar Cookies"
              declineButtonText="Rechazar"
              cookieName="gatsby-gdpr-google-analytics"
            >
              <p>
                Este sitio web almacena cookies en su navegador. Estas cookies
                se utilizan para recopilar información sobre cómo interactúa con
                este sitio web y nos permiten recordarlo. Usamos esta
                información para mejorar y personalizar su experiencia de
                navegación y para análisis y métricas sobre nuestros visitantes
                en este sitio web.{" "}
              </p>
              <p>
                Si la rechaza, no se realizará un seguimiento de su información
                cuando visite este sitio web. Se utilizará una sola cookie en su
                navegador para recordar su preferencia de no ser rastreado.
              </p>
            </CookieConsent>
          </Layout>
        </Layout>
      </>
    );
  }
}

export { LayoutGeneral };
