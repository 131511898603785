import React from "react";
import { Link } from "gatsby";
import { List, Row, Col } from "antd";

import "src/layout/footer/style.scss";

const data = [
  {
    title: "CLIMATE COATING®",
    list: [
      ["Membrana Termocerámica", ""],
      ["Rendimiento Endotérmico", ""],
      ["Greengard", ""],
    ],
  },
  {
    title: "LEGAL",
    list: [
      ["Aviso legal", "/aviso-legal"],
      ["Política de privacidad", "/politica-de-privacidad"],
    ],
  },
  {
    title: "CONTACTO",
    list: [
      ["Ponzano Madrid", ""],
      ["Teléfono: 915003333", ""],
    ],
  },
  {
    title: "PRODUCTOS PRINCIPALES",
    list: [
      ["ThermoActive", "/productos/thermoactive/"],
      ["ThermoProtect", "/productos/thermoprotect/"],
      ["ThermoPlus", "/productos/thermoplus/"],
      ["IndustrySpecial", "/productos/industryspecial/"],
    ],
  },
];
export const FooterComponent = (props) => (
  <div className="ccFooter">
    <List
      {...{
        grid: { gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 },
        dataSource: data,
        renderItem: ({ title, list }) => (
          <List.Item>
            <Col xs={{ offset: 6 }} sm={{ offset: 2 }}>
              <h3 style={{ marginTop: "15px" }}>{title}</h3>
              <ul style={{ paddingLeft: "5px" }}>
                {list.map(([text, link], j) => (
                  <li key={j}>{link ? <Link to={link}>{text}</Link> : text}</li>
                ))}
              </ul>
            </Col>
          </List.Item>
        ),
      }}
    />
  </div>
);

export default FooterComponent;
