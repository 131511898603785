import React from "react";

import { Layout, Menu } from "antd";

import MainMenu from "src/menus/mainMenu/Component";

import "src/menus/mainMenu/sider/style.scss";

import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";

const { Sider } = Layout;
class TopMenuTypeMobileSider extends React.Component {
  render() {
    const { siderCollapsed, toggleCollapsed } = this.props;

    return (
      <>
        <Sider
          theme="light"
          collapsedWidth={0}
          collapsible
          collapsed={siderCollapsed}
          onCollapse={toggleCollapsed}
        >
          {" "}
          <div className="logo" />
          <MainMenu
            {...{
              mode: "inline",
              theme: "light",
              style: { height: "100%" },
            }}
          />
        </Sider>
      </>
    );
  }
}

export default TopMenuTypeMobileSider;

/*
          <div className="logo" />
          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              nav 1
            </Menu.Item>
            <Menu.Item key="2" icon={<VideoCameraOutlined />}>
              nav 2
            </Menu.Item>

            <Menu.Item key="3" icon={<UploadOutlined />}>
              nav 3
            </Menu.Item>
          </Menu>

*/
